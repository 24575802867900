.root {
  margin: 0 auto;
  :global {
    .carousel .control-dots {
      top: 0;
      bottom: auto;
      text-align: right;
      padding-right: 1rem;
    }
  }
}

.imageMobile {
  max-height: 32rem;
}

.imageMobile img {
  object-fit: contain;
}

.imageDesktop {
  max-height: 35rem;
}
