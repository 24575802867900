@keyframes show {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.item {
  animation-name: show;
  animation-duration: 300ms;
  animation-timing-function: ease-in-out;
}
