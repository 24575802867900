@import './tailwind-variables';

* {
  outline-color: theme('colors.primary') !important;
}

html,
body {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: theme('colors.gray.900');
  background: theme('colors.gray.100');
  font-size: 16px; // for easy PX to REM conversion. We apply .body-1 as default
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
}

code {
  font: inherit !important;
}

html,
body,
#root {
  height: 100%;
}

/* CUSTOMIZATION */

.swiper-wrapper {
  align-items: stretch;
  .swiper-slide {
    height: auto !important;
  }
}

.input-range__slider {
  background: theme('colors.primary') !important;
  border-color: theme('colors.primary') !important;
}

.input-range__track--active {
  background: theme('colors.primary') !important;
}

.react-player > div > iframe {
  margin: 0px !important;
  width: 100% !important;
}

.tool-bar {
  background-color: #f5f5f5 !important;
  border-radius: 0 !important;
  padding: 0.375rem !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  margin-bottom: 0 !important;
}
.player {
  max-width: 100% !important;
}

.public-DraftStyleDefault-block {
  margin: 0 !important;
}

.react-date-picker__wrapper {
  border-radius: 0.375rem;
  padding: 0.5rem 0.75rem;
  background: theme('colors.white');
  border-color: theme('colors.gray.600');

  
}

.react-date-picker--disabled .react-date-picker__wrapper {
  background: theme('colors.gray.200');
  border-color: theme('colors.gray.600');
}



.special-label {
  display: none !important;
}

.react-tel-input .form-control {
  width: 100% !important;
  height: auto !important;
  border-radius: 0.375rem;
  border: solid 1px #9e9e9e !important;
  order: 1;
  padding: 0.5rem 0.75rem !important;
  padding-left: 60px !important;
  transition: none !important;
}

.form-control:focus {
  box-shadow: none !important;
  // box-shadow: 0px 0px 0px 1px #f04bb9 !important;
  outline: theme('colors.primary') solid 2px !important;
  border: solid 1px transparent !important;
  transition: none !important;
}

.react-calendar {
  width: 100% !important;
  border-color: #e4e4e7 !important;
  padding: 8px !important;
  border-radius: 0.375rem !important;
}

.react-calendar__tile--active {
  border-radius: 0.375rem !important;
}
pre code {
  font-family: monospace;
  background-color: white;
  border: 1px solid #999;
  display: block;
  padding: 10px;
  font-size: 0.8em;
}

@function hexToRGB($hex) {
  @return red($hex), green($hex), blue($hex);
}

// React Input Range (slider)
// $input-range-primary-color: theme('colors.default.primary');
$input-range-neutral-color: theme('colors.gray.700');
$input-range-disabled-color: theme('colors.gray.400');
$input-range-neutral-light-color: theme('colors.gray.500');
// $input-range-slider-background: theme('colors.primary');
$input-range-slider-width: 1.6rem;
$input-range-slider-height: 1.6rem;
$input-range-label-position-bottom: -1.6rem;
$input-range-label-value-position-top: -2.3rem;
$input-range-track-height: 0.5rem;
$transition-time: 150ms;
$input-range-slider-container-transition: left $transition-time ease-out !default;
$input-range-track-transition: left $transition-time ease-out,
  width $transition-time ease-out !default;
